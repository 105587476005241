import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClinicMedical, faLock, faSignInAlt, faUserPlus, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import JSEncrypt from 'jsencrypt';
import medicalTeamImage1 from './images/jonah1.jpg';
import medicalTeamImage2 from './images/jonah2.jpg';
import medicalTeamImage3 from './images/jonah2.jpg';
import medicalTeamImage4 from './images/jonah1.jpg';
import logoImage from './images/LOG.PNG'; // Import the logo image

function Login() {
  const [formData, setFormData] = useState({
    clinicName: '',
    password: '',
    showPassword: false,
  });

  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(medicalTeamImage1);
  const [currentSlogan, setCurrentSlogan] = useState('Comprehensive Clinic Management');
  const [showLogo, setShowLogo] = useState(true); // State to control logo display
  const navigate = useNavigate();

  const slogans = [
    'Comprehensive Clinic Management',
    'Streamlining Clinic Operations',
  ];

  const images = [
    medicalTeamImage1,
    medicalTeamImage2,
    medicalTeamImage3,
    medicalTeamImage4,
  ];

  useEffect(() => {
    // Set a timeout to hide the logo after 3 seconds
    const logoTimeout = setTimeout(() => {
      setShowLogo(false);
    }, 3000);

    const imageInterval = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 20000); // Change image every 20 seconds

    const sloganInterval = setInterval(() => {
      setCurrentSlogan(slogans[Math.floor(Math.random() * slogans.length)]);
    }, 10000); // Change slogan every 10 seconds

    return () => {
      clearTimeout(logoTimeout);
      clearInterval(imageInterval);
      clearInterval(sloganInterval);
    };
  }, []);

  useEffect(() => {
    // Dynamically add the Google Ads script
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9410756382950323';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    
    // Cleanup function to remove script if component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.clinicName === '' || formData.password === '') {
      toast.error('Please fill in all fields.', { position: 'top-left' });
      return;
    }

    setLoading(true);
    try {
      const publicKey = `-----BEGIN PUBLIC KEY-----
      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAup3FU135mAvJT6OheYW3
      pQyWf6jvS4duUMY4cXrlJXyGqu8HqvTU0ewPy6w2HhCPxWboNclkAkPhOCc4URNT
      x1Grg+mCsWmfhVimP2wtfmlBCJ09cyDMYf93iGj8RFf3CshY5yhppT/pX+RgTuXw
      ClpOXe24CLG2VF9suNylk+ReAMLyOxaekYofAMBvvrD4+GYPJgvkTMXCXCKp2PnO
      8+OjiltNMnoyqPEZoXHTV4EXtTrjYnwzSe0WZSSuzgVMhmtdx+IS4eisSumHV1eI
      wBeZwI0bYGxDCedPRassmSFgTFqkkcgIXmEP1n5w/08S/QPr2G+myKTeRqp5RJA5
      PQIDAQAB
      -----END PUBLIC KEY-----`;

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      const encryptedPassword = encrypt.encrypt(formData.password);

      const encryptedFormData = { ...formData, password: encryptedPassword, confirmPassword: encryptedPassword };

      const response = await fetch(urls.loginClinic, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(encryptedFormData),
      });

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        const sessionToken = responseData.sessionToken;
        navigate(`/dashboard?token=${sessionToken}`);
        toast.success('Login successful!', { position: 'top-left' });
      } else {
        toast.error('Login failed. Please check your credentials.', { position: 'top-left' });
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Login failed!', { position: 'top-left' });
    } finally {
      setLoading(false);
    }
  };

  if (showLogo) {
    return (
      <div className="logo-container">
        <img src={logoImage} alt="Logo" className="logo-image" />
      </div>
    );
  }

  return (
    <div className="login-container">
      <ToastContainer />
      {/* Google Ad Space */}
      <div className="google-ad-space">
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-9410756382950323"
          data-ad-slot="1234567890"
          data-ad-format="auto"></ins>
      </div>

      <div className="login-left">
        <div className="login-box">
          <h2 className="login-title">Welcome Back</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="login-form-group">
              <label className="login-form-label">
                <FontAwesomeIcon icon={faClinicMedical} /> Clinic Name
              </label>
              <input type="text" name="clinicName" value={formData.clinicName} onChange={handleChange} required className="login-form-input" />
            </div>
            <div className="login-form-group">
              <label className="login-form-label">
                <FontAwesomeIcon icon={faLock} /> Password
              </label>
              <div className="password-input-container">
                <input type={formData.showPassword ? 'text' : 'password'} name="password" value={formData.password} onChange={handleChange} required className="login-form-input" />
                <FontAwesomeIcon icon={formData.showPassword ? faEyeSlash : faEye} className="password-toggle" onClick={togglePasswordVisibility} />
              </div>
            </div>
            <div className="login-form-buttons">
              <button type="submit" disabled={loading} className="login-btn login-btn-login">
                {loading ? 'Logging in...' : <><FontAwesomeIcon icon={faSignInAlt} /> Log In</>}
              </button>
              <button type="button" onClick={() => navigate('/clinic-registration')} className="login-btn login-btn-register">
                <FontAwesomeIcon icon={faUserPlus} /> Register
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="login-right">
        <div className="slogan">
          <h1>Clinic-Pro</h1>
          <p>{currentSlogan}</p>
        </div>
        <img src={currentImage} alt="Medical Team" className="login-image" />
      </div>
    </div>
  );
}

export default Login;
