import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { urls } from './config.dev';
import './viewfile.css';
import { useNavigate } from 'react-router-dom';
import TreatmentPlanModal from './TreatmentPlanModal';
import Select from 'react-select';
import Topbar from './Topbar'; // Import the Topbar component
import EditFileModal from './EditFileModal';
import axios from 'axios'; // Import axios


function ViewFile() {
    const [fileData, setFileData] = useState(null);
    const [editableFields, setEditableFields] = useState({});
    const [labTestsAvailable, setLabTestsAvailable] = useState([]);
    const [selectedLabTests, setSelectedLabTests] = useState([]);
    const [radiologyTestsAvailable, setRadiologyTestsAvailable] = useState([]);
    const [selectedRadiologyTests, setSelectedRadiologyTests] = useState([]);
    const [submittingLabTest, setSubmittingLabTest] = useState(false);
    const [submittingRadiologyTest, setSubmittingRadiologyTest] = useState(false);
    const [submittingChanges, setSubmittingChanges] = useState(false);
    const [updatingField, setUpdatingField] = useState('');
    const [diagnosisClicked, setDiagnosisClicked] = useState(false);
    const [showTreatmentPlanModal, setShowTreatmentPlanModal] = useState(false);
    const [showTreatmentChatModal, setShowTreatmentChatModal] = useState(false);
    const [treatmentNotesClicked, setTreatmentNotesClicked] = useState(true);
    const [clinicalNotesClicked, setClinicalNotesClicked] = useState(false);
    const [signsAndSymptomsClicked, setSignsAndSymptomsClicked] = useState(false);
    const [treatmentPlanClicked, setTreatmentPlanClicked] = useState(false);
    const [treatmentChatClicked, setTreatmentChatClicked] = useState(false);
    const treatmentChatModalRef = useRef(null);
    const [diagnosisText, setDiagnosisText] = useState('');
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
    const [urlToken, setUrlToken] = useState('');
    const [showLabTestPrompt, setShowLabTestPrompt] = useState(false);
    const [showRadiologyTestPrompt, setShowRadiologyTestPrompt] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null); // State to store the PDF URL
    const pathParts = window.location.pathname.split('/');
const tokenFromUrl = pathParts[2]; // Assuming the token is the 3rd part of the path

const [isModalOpen, setIsModalOpen] = useState(false); //

    const signsAndSymptomsRef = useRef(null);
    const clinicalNotesRef = useRef(null);
    const diagnosisRef = useRef(null);
    const treatmentPlanRef = useRef(null);
    const treatmentNotesRef = useRef(null);

   

   
    const cellStyle = {
        padding: 0,
        border: 'none',
        background: 'none',
        whiteSpace: 'nowrap',
        overflow: 'visible',
    };

    const { token, fileId } = useParams(); // Extract token and fileId from URL
    const navigate = useNavigate();

    const [employeeName, setEmployeeName] = useState(''); // Define employeeName state
    const diagnosisOptions = [
     
        { value: 'Malaria', label: 'Malaria' },
        { value: 'Pneumonia', label: 'Pneumonia' },
        { value: 'Tuberculosis', label: 'Tuberculosis (TB)' },
        { value: 'TyphoidFever', label: 'Typhoid Fever' },
        { value: 'Gastroenteritis', label: 'Gastroenteritis' },
        { value: 'Syphilis', label: 'Syphilis' },
        { value: 'Gonorrhea', label: 'Gonorrhea' },
        { value: 'Urinary Tract Infections', label: 'Urinary Tract Infections' },
       
        { value: 'Endometritis', label: 'Endometritis' },
        { value: 'PelvicInflammatoryDisease', label: 'Pelvic Inflammatory Disease' },
        { value: 'Cervicitis', label: 'Cervicitis' },
        { value: 'Vulvitis', label: 'Vulvitis' },
        { value: 'Vaginitis', label: 'Vaginitis' },
        { value: 'Bartholinitis', label: 'Bartholinitis' },
        { value: 'Urethritis', label: 'Urethritis' },
        { value: 'VulvarDermatitis', label: 'Vulvar Dermatitis' },
        { value: 'Urethritis', label: 'Urethritis' },

        { value: 'HIV/AIDS', label: 'HIV/AIDS' },
       
        { value: 'HepatitisB', label: 'Viral Hepatitis (Hepatitis B)' },
        { value: 'HepatitisC', label: 'Viral Hepatitis (Hepatitis C)' },
        { value: 'Measles', label: 'Measles' },
        { value: 'Meningitis', label: 'Meningitis (Bacterial and Viral)' },
        { value: 'Cholera', label: 'Cholera' },
        { value: 'Shigella', label: 'Shigella' },
        
       ,
        { value: 'Schistosomiasis', label: 'Schistosomiasis' },
        { value: 'Hookworm', label: 'Hookworm' },
        { value: 'Roundworm', label: 'Roundworm' },
        { value: 'Leprosy', label: 'Leprosy' },
        { value: 'Brucellosis', label: 'Brucellosis' },
       
        
        { value: 'Hypertension', label: 'Hypertension' },
        { value: 'DiabetesMellitus', label: 'Diabetes Mellitus' },
        { value: 'COPD', label: 'Chronic Obstructive Pulmonary Disease (COPD)' },
        { value: 'Asthma', label: 'Asthma' },
        { value: 'IschemicHeartDisease', label: 'Ischemic Heart Disease' },
        { value: 'CervicalCancer', label: 'Cervical Cancer' },
        { value: 'BreastCancer', label: 'Breast Cancer' },
        { value: 'ChronicKidneyDisease', label: 'Chronic Kidney Disease' },
        { value: 'Epilepsy', label: 'Epilepsy' },
        { value: 'SickleCellDisease', label: 'Sickle Cell Disease' },
        { value: 'Depression', label: 'Depression' },
        { value: 'Anxiety', label: 'Anxiety' },
        { value: 'Psychosis', label: 'Psychosis' },
        
    
        
        { value: 'AntenatalComplications', label: 'Antenatal Complications' },
        { value: 'PreEclampsia', label: 'Pre-eclampsia' },
        { value: 'Eclampsia', label: 'Eclampsia' },
        { value: 'PostpartumHemorrhage', label: 'Postpartum Hemorrhage' },
        { value: 'ObstetricFistula', label: 'Obstetric Fistula' },
        { value: 'NeonatalSepsis', label: 'Neonatal Sepsis' },
        { value: 'LowBirthWeight', label: 'Low Birth Weight' },
        { value: 'Malnutrition', label: 'Malnutrition' },
        { value: 'GrowthDisorders', label: 'Growth and Developmental Disorders' },
        
    
       
        { value: 'Iron-Deficiency Anemia', label: 'Iron-Deficiency Anemia' },
        { value: 'Vitamin A Deficiency', label: 'Vitamin A Deficiency' },
        { value: 'Iodine Deficiency Disorders', label: 'Iodine Deficiency Disorders' },
    
    
        { value: 'Snake Bites', label: 'Snake Bites' },
        { value: 'Pesticide Poisoning', label: 'Pesticide Poisoning' },
        { value: 'Food  Poisoning', label: 'Food Poisoning' },
        { value: 'Silicosis', label: 'Silicosis)' },
        { value: 'Heat Stroke', label: 'Heat Stroke' },
      
    
        
        { value: 'Scabies', label: 'Scabies' },
        { value: 'Dermatophytosis', label: 'Dermatophytosis' },
        { value: 'Ringworm', label: 'Ringworm' },
        { value: 'Eczema', label: 'Eczema' },
        { value: 'Impetigo', label: 'Impetigo' },
        { value: 'Leprosy', label: 'Leprosy (Hansen\'s Disease)' },
    
        
        { value: 'Cataracts', label: 'Cataracts' },
        { value: 'Glaucoma', label: 'Glaucoma' },
        { value: 'Trachoma', label: 'Trachoma' },
        { value: 'Conjunctivitis', label: 'Conjunctivitis' },
        { value: 'Refractive Errors', label: 'Refractive Errors' },
    
        
        { value: 'Dental Caries', label: 'Dental Caries (Tooth Decay)' },
        { value: 'Periodontal Diseases', label: 'Periodontal Diseases ' },
        { value: 'Gingivitis', label: ' Gingivitis' },
        { value: 'Oral Cancers', label: 'Oral Cancers' },
    
      
        { value: 'Arthritis', label: 'Arthritis' },
        { value: 'Osteoporosis', label: 'Osteoporosis' },
        { value: 'Lumbar Spondylosis', label: ' Lumbar Spondylosis' },
    
        
        { value: 'Trauma and Injuries', label: 'Trauma and Injuries ' },
        
        { value: 'Road Traffic Accidents', label: 'Road Traffic Accidents' },
        { value: 'Burns', label: 'Burns' },
        { value: 'Snake Bites', label: 'Snake Bites' },
        { value: 'Rabies', label: ' Rabies' },
    ];
    useEffect(() => {
        // Function to extract the token from the URL
        const extractToken = () => {
          try {
            // Get the full URL
            const url = window.location.href;
    
            // Extract the token from the URL (second-to-last segment)
            const extractedToken = url.split('/').slice(-2, -1)[0];
    
            // Set the token in the state
            setUrlToken(extractedToken);
          } catch (error) {
            console.error("Error extracting token from URL:", error);
          }
        };
    
        // Call the function to extract the token
        extractToken();
      }, []); // Run this effect once on component mount
    useEffect(() => {
        const fetchEmployeeName = async () => {
            try {
                const securityResponse = await fetch(urls.security, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });

                if (securityResponse.ok) {
                    const securityData = await securityResponse.json();

                    if (securityData.message === 'Session valid') {
                        setEmployeeName(securityData.employee_name); // Set the employee name
                        localStorage.setItem('token', securityData.clinic_session_token);
                        fetchAvailableLabTests();
                        fetchAvailableRadiologyTests();
                    } else if (securityData.error === 'Session expired') {
                        navigate(`/dashboard?token=${securityData.clinic_session_token}`);
                    } else {
                        navigate('/login');
                    }
                } else {
                    throw new Error('Failed to perform security check');
                }
            } catch (error) {
                console.error('Error performing security check:', error);
                navigate('/login');
            }
        };

        fetchEmployeeName();
    }, [navigate, token]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            fetchFileData();
            fetchAvailableLabTests();
            fetchAvailableRadiologyTests();
        }, 5000);

        return () => clearInterval(interval);
    }, [fileId]);

        // Function to handle opening the treatment plan modal
        const handleOpenTreatmentPlanModal = () => {
            setShowTreatmentPlanModal(true);
        };
              // Function to handle opening the treatment chat modal
              const handleOpenTreatmentChatModal = () => {
                setShowTreatmentChatModal(true);
                setTimeout(() => {
                    if (treatmentChatModalRef.current) {
                        treatmentChatModalRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 100); // Delay to ensure the modal is fully opened
            };
            
        // Function to handle closing the treatment plan modal
        const handleCloseTreatmentPlanModal = () => {
            setShowTreatmentPlanModal(false);
        };
        
    // Function to handle closing the treatment chat modal
    const handleCloseTreatmentChatModal = () => {
        setShowTreatmentChatModal(false);
    };

    
        // JSX for button to open treatment plan modal
        const TreatmentPlanButton = (
            <button onClick={handleOpenTreatmentPlanModal}>Open Treatment Plan Modal</button>
        );
           // JSX for button to open treatment chat modal
    const TreatmentChatButton = (
        <button onClick={handleOpenTreatmentChatModal}>Edit Treatment Chat</button>
    )
    useEffect(() => {
        if (fileData) {
            const adjustHeight = (ref) => {
                if (ref.current) {
                    ref.current.style.height = 'auto'; // Reset the height to auto
                    ref.current.style.height = `${ref.current.scrollHeight}px`; // Set the height based on scrollHeight
                }
            };
    
            adjustHeight(signsAndSymptomsRef);
            adjustHeight(clinicalNotesRef);
            adjustHeight(diagnosisRef);
            adjustHeight(treatmentPlanRef);
            adjustHeight(treatmentNotesRef);
        }
    }, [fileData]);
    


    // Fetch file data from backend
    const fetchFileData = () => {
        fetch(urls.viewfile + `?fileId=${fileId}`)
            .then(response => response.json())
            .then(data => {
                setFileData(data);
                setEditableFields({
                    blood_pressure: data.fileData.blood_pressure,
                    temperature: data.fileData.temperature,
                    spo2: data.fileData.spo2,
                    body_weight: data.fileData.body_weight,
                    signs_and_symptoms: data.fileData.signs_and_symptoms,
                    clinical_notes: data.fileData.clinical_notes,
                    lab_tests: data.fileData.lab_tests,
                    radiology_exams: data.fileData.radiology_exams,
                    diagnosis: data.fileData.diagnosis,
                    treatment_plan: data.fileData.treatment_plan,
                    treatment_notes: data.fileData.treatment_notes || '', // Include treatment notes
                });
            })
            .catch(error => {
                console.error('Error fetching file data:', error);
            });
    };

    const fetchAvailableLabTests = async () => {
        try {
            const pathParts = window.location.pathname.split('/');
            const tokenFromUrl = pathParts[2]; // Assuming the token is the 3rd part of the path
            const payload = { token: tokenFromUrl };
    
            const response = await fetch(urls.testsavailable, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) throw new Error('Failed to fetch available lab tests');
    
            const data = await response.json();
            setLabTestsAvailable(data);
        } catch (error) {
            console.error('Error fetching available lab tests:', error);
        }
    };
    const fetchAvailableRadiologyTests = async () => {
        try {
            const pathParts = window.location.pathname.split('/');
            const tokenFromUrl = pathParts[2]; // Assuming the token is the 3rd part of the path
            const payload = { token: tokenFromUrl };
    
            const response = await fetch(urls.radiologytestsavailable, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) throw new Error('Failed to fetch available radiology exams');
    
            const data = await response.json();
            setRadiologyTestsAvailable(data);
        } catch (error) {
            console.error('Error fetching available radiology exams:', error);
        }
    };
        
    const handleFieldChange = (fieldName, value) => {
        setEditableFields(prevState => {
            const updatedFields = {
                ...prevState,
                [fieldName]: value,
            };
            console.log(`Field ${fieldName} updated to:`, updatedFields[fieldName]);
            return updatedFields;
        });
    };
  
    


    
    // Show prompt to request new lab tests
    const handleLabTestRequest = () => {
        setShowLabTestPrompt(true);
    };

    // Show prompt to request new radiology tests
    const handleRadiologyTestRequest = () => {
        setShowRadiologyTestPrompt(true);
    };

   

    const submitLabTests = () => {
        setSubmittingLabTest(true); // Set submitting status to true
        fetch(urls.submitlabtest, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fileId: fileId,
                tests: selectedLabTests,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Lab tests submitted:', data);
            setShowLabTestPrompt(false);
            toast.success("Lab tests submission successful");
            // Optionally update state here with new data from the server if needed
        })
        .catch(error => {
            console.error('Error submitting lab tests:', error);
            toast.error("Lab tests submission failed");
        })
        .finally(() => {
            setSubmittingLabTest(false); // Reset submitting status
        });
    };
    
    const submitRadiologyTest = () => {
        setSubmittingRadiologyTest(true); // Set submitting status to true
        fetch(urls.submitradiologyexam, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fileId: fileId,
                tests: selectedRadiologyTests,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Radiology tests submitted:', data);
            setShowRadiologyTestPrompt(false);
            toast.success("Radiology tests submission successful");
            // Optionally update state here with new data from the server if needed
        })
        .catch(error => {
            console.error('Error submitting radiology tests:', error);
            toast.error("Radiology tests submission failed");
        })
        .finally(() => {
            setSubmittingRadiologyTest(false); // Reset submitting status
        });
    };
   const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
        // Access the textarea element from diagnosisRef
        const textarea = diagnosisRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        // Get the current value of the textarea
        const textValue = textarea.value;

        // Split the text before and after the selection
        const textBefore = textValue.substring(0, start);
        const textAfter = textValue.substring(end);

        // Split the text before the cursor into words
        const words = textBefore.split(/\s+/);

        // Replace the last word with the selected diagnosis value
        words[words.length - 1] = selectedOption.value;

        // Update the diagnosisText state with the modified text
        const updatedText = words.join(' ') + textAfter;
        setDiagnosisText(updatedText);

        // Adjust the cursor position after the update
        const newCursorPosition = (words.join(' ') + ' ').length;
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPosition, newCursorPosition);
            textarea.focus();
        }, 0);
    }
};

    const submitChanges = async () => {
        try {
            setSubmittingChanges(true);
            const payload = {
                fileId: fileId,
                ...editableFields,
            };
            const response = await fetch(urls.submitchanges, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            if (data && data.message === "Changes submitted successfully") {
                toast.success("Changes submitted successfully");
                // Optionally update state here with new data from the server if needed
            } else {
                toast.error("Oops, something went wrong");
            }
        } catch (error) {
            console.error('Error submitting changes:', error);
            toast.error("Oops, something went wrong");
        } finally {
            setSubmittingChanges(false);
        }
    };
    
    // New function to handle submission of individual field updates
    const submitFieldUpdate = async (fieldName) => {
        try {
            setUpdatingField(fieldName); // Set the field being updated
            const payload = {
                fileId: fileId,
                [fieldName]: editableFields[fieldName], // Include only the updated field
            };
            const response = await fetch(urls.submitchanges, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            if (data && data.message === "Changes submitted successfully") {
                toast.success("Data updated");
            } else {
                toast.error("Oops, something went wrong");
            }
        } catch (error) {
            console.error('Error submitting changes:', error);
            toast.error("Oops, something went wrong");
        } finally {
            setUpdatingField(''); // Reset the field being updated
        }
    };
    
    
     // Navigate to discharge form route with employeeName from URL
     const navigateToDischargeForm = () => {
        navigate(`/discharge-form/${employeeName}/${fileId}`); // Navigate to discharge form route
    };

    // Navigate to referral form route with employeeName from URL
    const navigateToReferralForm = () => {
        navigate(`/referral-form/${employeeName}/${fileId}`); // Navigate to referral form route
    };
    
    const textAreaStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '20px',
        resize: 'vertical',
    };
    const textAreaStyle2 = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '15px',
        resize: 'vertical',
    };
    const customStyles = {
        container: (provided) => ({
            ...provided,
            fontSize: '16px', // Reduced font size
            fontFamily: 'Arial, sans-serif',
            marginBottom: '10px',
            width: '100%',
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#007BFF' : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
            '&:hover': {
                borderColor: '#007BFF',
            },
            height: '10px', // Reduced height of the select input
            transition: 'border-color 0.3s ease', // Smooth transition for border color
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6c757d',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#007BFF' : '#000',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#007BFF',
                color: '#fff',
            },
            fontSize: '12px', // Smaller font size for options
            padding: '8px 10px', // Reduced padding for options
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#333',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            marginTop: '0',
            backgroundColor: '#000',
            animation: 'fadeIn 0.3s ease-in-out', // Animation for dropdown appearance
        }),
        menuList: (provided) => ({
            ...provided,
            padding: '5px',
            color: '#fff',
            fontSize: '12px', // Smaller font size for dropdown list
            gap: '2px', // Reduced space between options
            maxHeight: '400px', // Set a maximum height for scrolling
            overflowY: 'auto', // Enable vertical scrolling
        }),
    };
    
    
    // Add the keyframes for the fade-in animation to your global CSS or within a styled component
    const styles = `
        @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(-10px); }
            100% { opacity: 1; transform: translateY(0); }
        }
    `;
    
    // Append the styles to the document head (if not using styled-components)
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    const openModal = () => {
        setIsModalOpen(true); // Open the modal
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
    };
    const handlePrint = async () => {
        try {
            const response = await axios.post(urls.printfile, {
                fileId,
                token, // Send token if needed
            }, {
                responseType: 'blob', // Expect a Blob response (PDF)
            });
    
            // Create a URL for the PDF blob
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
    
            // Automatically open the PDF in a new tab
            const newWindow = window.open(pdfUrl);
            if (newWindow) {
                newWindow.focus(); // Ensure the new tab is focused
            } else {
                // If popup blockers prevent opening a new tab, fallback to manual download
                alert('Please allow popups for this website to view the PDF.');
            }
    
            // Optionally, you can set the PDF URL in the state if needed
            // setPdfUrl(pdfUrl); 
    
        } catch (error) {
            console.error('Error fetching PDF:', error);
            // Optionally handle error, e.g., show an alert or message to the user
        }
    };
    const handleCancelInvestigation = async (type) => {
        try {
            const response = await fetch(urls.cancelinvestigation, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileId, type }), // Send fileId and type
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            // Handle the result (e.g., show a success message)
            console.log(result);
        } catch (error) {
            console.error('Error canceling investigation:', error);
            // Handle error (e.g., show an error message)
        }
    };
    return (
        <div className="view-file-container">
        <Topbar token={urlToken} />
             <ToastContainer />
            {fileData ? (
                <div className="file-details">
                    <h1>Date Time: {fileData.fileData?.date_time}</h1>
                    <div className="patient-details">
                        <p>Name: {fileData.fileData?.first_name} {fileData.fileData?.last_name}</p>
                        <p>Phone Number: {fileData.fileData?.phone_number}</p>
                        <p>Age: {fileData.fileData?.age}</p>
                        <p>Sex: {fileData.fileData?.sex}</p>
                        <p>Address: {fileData.fileData?.address}</p>
                    </div>
                    <div className="vitals">
    <p>Blood Pressure: {fileData.fileData?.blood_pressure}</p>
    <p>Temperature: {fileData.fileData?.temperature}</p>
    <p>SPO2: {fileData.fileData?.spo2}</p>
    <p>Body Weight: {fileData.fileData?.body_weight}</p>
    <p>Height: {fileData.fileData?.height}</p>
    <p>Pulse Rate: {fileData.fileData?.pulse_rate}</p>
    <p>Respiratory Rate: {fileData.fileData?.respiratory_rate}</p>
</div>

<div className="signs-symptoms">
    <h2>Presenting Complaints:</h2>
    <div className="editable-field">
        <textarea
            ref={signsAndSymptomsRef}
            defaultValue={editableFields.signs_and_symptoms}
            onFocus={() => {
                setSignsAndSymptomsClicked(true);
                openModal(); // Automatically open the modal when the text field is clicked
            }}
            disabled={updatingField === 'signs_and_symptoms'}
            style={textAreaStyle}
        />
    </div>

    {/* Render the modal conditionally */}
    {isModalOpen && (
        <EditFileModal 
            fileId={fileId} // Pass the file ID
            token={token} // Pass the token
            employeeName={employeeName} // Pass the employee name
            clinicalNotes={editableFields.clinical_notes} // Pass clinical notes
            signsAndSymptoms={editableFields.signs_and_symptoms} // Pass signs and symptoms
            diagnosis={editableFields.diagnosis} // Pass diagnosis
            onClose={closeModal} // Handle modal close
        />
    )}
</div>

<div className="clinical-notes">
    <h2>Clinical Notes:</h2>
    <div className="editable-field">
        <textarea
            ref={clinicalNotesRef}
            defaultValue={editableFields.clinical_notes}
            onFocus={() => {
                setClinicalNotesClicked(true);
                openModal(); // Automatically open the modal when the text field is clicked
            }}
            disabled={updatingField === 'clinical_notes'}
            style={textAreaStyle}
        />
    </div>
</div>

<div className="diagnosis">
    <h2>Diagnosis:</h2>
    <div className="editable-field">
        <textarea
            ref={diagnosisRef}
            defaultValue={editableFields.diagnosis}
            onChange={(e) => {
                setDiagnosisText(e.target.value);
                setEditableFields(prev => ({ ...prev, diagnosis: e.target.value })); // Sync with editableFields
            }}
            placeholder="Enter diagnosis here... or leave this field empty if you still need to wait for the investigation results"
            style={textAreaStyle}
            onFocus={() => {
                setDiagnosisClicked(true);
                openModal(); // Automatically open the modal when the text field is clicked
            }}
        />
    </div>
</div>


                  
    
   
<div className="treatment-plan">
    <h2>Treatment Plan:</h2>
    <div className="editable-field">
        <textarea
            ref={treatmentPlanRef}
            defaultValue={editableFields.treatment_plan}
            onClick={handleOpenTreatmentPlanModal} // Add onClick event to open the modal
            disabled={false} // Make textarea editable
            placeholder="Click here to update treatment plan to prescribe medication for this patient"
            style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '20px',
                resize: 'vertical',
                cursor: 'pointer', // Change cursor to pointer for better UX
            }}
        />
    </div>
</div>
<div className="treatment-notes">
    <h2>Treatment Chat:</h2>
    <div className="editable-field">
        <textarea
            ref={treatmentNotesRef}
            defaultValue={editableFields.treatment_notes}
            disabled={true}
            placeholder="No treatment notes for this patient yet"
            style={textAreaStyle2}
        />
    </div>
</div>
                   
    {showTreatmentPlanModal && (
    <div className="treatment-plan-modal open">
        <div className="treatment-plan-modal-content">
            <TreatmentPlanModal
                fileId={fileId} // Pass fileId to the modal
                employeeName={employeeName} // Pass employeeName to the modal
                token={tokenFromUrl} // Pass token to the modal
                onClose={handleCloseTreatmentPlanModal} // Pass onClose event handler to close the modal
            />
        </div>
    </div>
)}

<div className="unique-lab-radiology">
  <h2>Lab Tests done :</h2>
  <p>{fileData.fileData?.lab_tests?.join(', ') || 'No lab tests done'}</p>
  <button onClick={handleLabTestRequest}>Request New Lab Test</button>
  
  {showLabTestPrompt && (
    <div
      className="unique-modal-overlay"
      onClick={() => setShowLabTestPrompt(false)} // Clicking outside closes the modal
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      <div
        className="unique-modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '400px',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative'
        }}
      >
        <h2>Select Lab Tests</h2>
        {labTestsAvailable.map(test => (
          <div key={test.name}>
            <input
              type="checkbox"
              id={test.name}
              value={test.name}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedLabTests(prevTests => [...prevTests, test.name]);
                } else {
                  setSelectedLabTests(prevTests => prevTests.filter(prevTest => prevTest !== test.name));
                }
              }}
            />
            <label htmlFor={test.name}>{test.name} - UGX {test.price}</label>
          </div>
        ))}

        {/* Sticky Footer for Buttons */}
        <div style={{ position: 'sticky', bottom: '0', backgroundColor: 'white', paddingTop: '10px' }}>
          <button onClick={submitLabTests} disabled={submittingLabTest}>
            {submittingLabTest ? 'Please wait...' : 'Submit Laboratory Request'}
          </button>
          <button onClick={() => setShowLabTestPrompt(false)}>Cancel</button>
        </div>
      </div>
    </div>
  )}

  <h2>Radiology Exams Done:</h2>
  <p>{fileData.fileData?.radiology_exams?.join(', ') || 'No radiology exams done'}</p>
  <button onClick={handleRadiologyTestRequest}>Request New Radiology Test</button>

  {showRadiologyTestPrompt && (
    <div
      className="unique-modal-overlay"
      onClick={() => setShowRadiologyTestPrompt(false)} // Clicking outside closes the modal
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      <div
        className="unique-modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '400px',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative'
        }}
      >
        <h2>Select Radiology Tests</h2>
        {radiologyTestsAvailable.map(test => (
          <div key={test.name}>
            <input
              type="checkbox"
              id={test.name}
              value={test.name}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedRadiologyTests(prevTests => [...prevTests, test.name]);
                } else {
                  setSelectedRadiologyTests(prevTests => prevTests.filter(prevTest => prevTest !== test.name));
                }
              }}
            />
            <label htmlFor={test.name}>{test.name} - UGX {test.price}</label>
          </div>
        ))}

        {/* Sticky Footer for Buttons */}
        <div style={{ position: 'sticky', bottom: '0', backgroundColor: 'white', paddingTop: '10px' }}>
          <button onClick={submitRadiologyTest} disabled={submittingRadiologyTest}>
            {submittingRadiologyTest ? 'Please wait...' : 'Submit Radiology Request'}
          </button>
          <button onClick={() => setShowRadiologyTestPrompt(false)}>Cancel</button>
        </div>
      </div>
    </div>
  )}
</div>


                    <div className="status">
                    <p>Lab Status: {fileData.labStatusMessage} 
                        {fileData.labStatusMessage === 'This patient is making payment for laboratory services' && (
                            <span 
                                style={{ color: 'blue', cursor: 'pointer' }} 
                                onClick={() => handleCancelInvestigation('lab')}
                            >
                                {' (click here if you want to call off the lab request)'}
                            </span>
                        )}
                    </p>
                    <p>Radiology Status: {fileData.radiologyStatusMessage || 'Not available'} 
                        {fileData.radiologyStatusMessage === 'This patient is making payment for radiology services' && (
                            <span 
                                style={{ color: 'blue', cursor: 'pointer' }} 
                                onClick={() => handleCancelInvestigation('radiology')}
                            >
                                {' (click here if you want to call off the radiology request)'}
                            </span>
                        )}
                    </p>
                    </div>
                </div>
            ) : (
                <p>Loading file data...</p>
            )}
            {/*<button onClick={navigateToDischargeForm}>Discharge Form</button>*/}
            {/*<button onClick={navigateToReferralForm}>Referral Form</button>*/}
            <button onClick={handlePrint}>Print file</button>
        </div>
    );
};

const textAreaStyle = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
    height: '200px',
    resize: 'vertical',
};

const buttonStyle = {
    marginLeft: '10px',
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    cursor: 'pointer',
};

export default ViewFile;
